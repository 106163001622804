<script>
import Vue from 'vue';
import GlobalVue from '@helper/Global.vue';
import moment from 'moment';
import Gen from '@helper/Gen';
import FOGen from '@helper/FOGen';
import Croppa from 'vue-croppa';
import swal from 'sweetalert';
import VTooltip from 'v-tooltip'
import VueHtmlToPaper from 'vue-html-to-paper';
import {VueMasonryPlugin} from 'vue-masonry';
import {vueTopprogress} from 'vue-top-progress'

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

/* Vue plugins */
Vue.use(VueMasonryPlugin)
Vue.use(Croppa);
Vue.use(VTooltip);
Vue.use(vueTopprogress)
Vue.use(VueHtmlToPaper, options);

moment.locale("id")
global.moment = moment

/* CSS */

// import "@frontend/css/bootstrap.css"
import "@frontend/css/custom.scss"
import "@frontend/css/responsive.scss"

// import "@frontend/css/style.css"
/* JS */
import "@plugins/jquery/dist/jquery.min.js"
import "@plugins/jquery-validation/jquery-validation.js"
import "@helper/general_function.js"
import "@plugins/extension.js"
import "@frontend/js/main.js"

/* Vue Component */
Vue.component("ImagePreview",()=>import("@backend/components/ImagePreview.vue"))
Vue.component("Component404",()=>import("@frontend/components/Component404.vue"))
Vue.component("VInfo", ()=>import("@frontend/components/VInfo.vue"))
Vue.component("BreadCrumb", ()=>import("@frontend/part/BreadCrumb.vue"))
// Vue.component("VAlert", ()=>import("@frontend/components/VAlert.vue"))

global.$ = global.jQuery
global.Gen = Gen
global.App.user = null

export default {
  name:"FoMain",
  extends: GlobalVue,
  async mounted(){
    window.history.scrollRestoration = 'manual'
    global.FOGen = FOGen
    moment.locale(this.hl)
    /* CSS */
    Gen.loadCss("https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900&display=swap")
    Gen.loadCss("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;700&display=swap")
    Gen.loadCss("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap")
    Gen.loadCss("/frontend/css/bootstrap.css")
    Gen.loadCss("/frontend/css/style.css")
    Gen.loadCss("/frontend/css/dark.css")
    Gen.loadCss("/frontend/css/font-icons.css")
    Gen.loadCss("/frontend/css/animate.css")
    Gen.loadCss("/frontend/css/magnific-popup.css")
    Gen.loadCss("/frontend/css/responsive.css")
    Gen.loadCss("/frontend/css/font_style.css")
    Gen.loadCss("/frontend/css/aos.css")
    Gen.loadCss("/frontend/css/custom_l9.css?v=3.0.1")
    Gen.loadCss("/frontend/css/responsive_l9.css?v=2.0")
    Gen.loadCss("/frontend/css/components/radio-checkbox.css")
    
    Gen.loadCss("/frontend/css/loader.css?v=0.1")
    /* Load Variable */
    this.refreshVariableFo()

    /* JS */    
    // await Gen.loadScript("/frontend/js/jquery.js")
    await Gen.loadScript("/frontend/js/plugins.js")
    await Gen.loadScript("/frontend/js/aos.js")
    await Gen.loadScript("/frontend/js/functions.js?v=1.4")
    await Gen.loadScript("/frontend/js/custom.js?v=0.6")
    
        global.$ = global.jQuery

        /* Ready Loaded */
        $(document).ready(()=>{
            this.$root.ready = true
            $(".page-transition-wrap").remove()
        })

        AOS.init();

        if(Gen.getStorage("fotk")){
           Gen.putCookie("fotk", Gen.getStorage("fotk"))
        }

        var self = this
        $(document).on("click", "a", function (e) {
            if ($(this).is("[reload]")) return;
            if ($(this).is("[target]")) return;
            if ($(this).attr("href") == "javascript:;") return;
            if ($(this).attr("href")[0] == "#") return;
            if ($(this).attr("href").indexOf(".") > -1) {
                e.preventDefault();
                return;
            }
            if ($(this).attr("href").indexOf(":") < 0) {
                e.preventDefault();
                if (self.$route.fullPath != $(this).attr("href")) self.$router.push($(this).attr("href"))
            }
        })
        $(document).on('keydown', '.form-control', function (e) {
            if (e.which === 32 && e.target.selectionStart === 0) {
                return false;
            }
        });

        this.$root.topProgress = this.$refs.topProgress
        this.$root.topProgress.start()
        if(Gen.getStorage('legalAge') < 21) this.openModalAge()
    },
    components: {
        vueTopprogress
    },
    methods:{
        openModalAge(){
            VModal.openModal("ModalLegalAge", {}, {})
        }
    },
    watch: {
        $route(){
            if(typeof $ != 'undefined') $("html,body").scrollTop(0)
            AOS.init();
            if(Gen.getStorage('legalAge') < 21) this.openModalAge()
            this.$root.topProgress.start()
            $("#primary-menu > ul").removeClass('d-block')
        }
    },
}

$(document).scroll(function() {
    var y = $(this).scrollTop();
    if (y > 200) {
        $('.bottom_navigation').fadeIn();
    } else {
        $('.bottom_navigation').fadeOut();
    }
});
</script>

<template>
    <div id="FoMain">
        <vueTopprogress ref="topProgress" v-bind="{color:'#ff0000'}"></vueTopprogress>
        <router-view/>
    </div>
</template>