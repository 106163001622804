import Gen from "@helper/Gen";

/* eslint-disable no-unused-vars */
window.toslug = function (text) {
	return text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
}

window.alphaOnly = function (e) {
	if (/^(8)$/g.test(e.keyCode)) return true;
	// return /^[a-zA-Z ]$/g.test(String.fromCharCode(e.which));
	Gen.interval(() => {
		e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, '')
	}, 25, 50)
}

window.fullNameKey = function (e) {
	if (/^(8)$/g.test(e.keyCode)) return true;
	// return /^[a-zA-Z ]$/g.test(String.fromCharCode(e.which));
	Gen.interval(() => {
		e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, '')
	}, 25, 50)
}

window.alphaNumber = function (e) {
	if (/^(8)$/g.test(e.keyCode)) return true;
	// return /^[a-zA-Z0-9 ]$/g.test(String.fromCharCode(e.which));
	Gen.interval(() => {
		e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')
	}, 25, 50)
}

window.websiteKey = function (e) {
	if (/^(8)$/g.test(e.keyCode)) return true;
	// return /^[a-zA-Z0-9/:. ]$/g.test(String.fromCharCode(e.which));
	Gen.interval(() => {
		e.target.value = e.target.value.replace(/[^a-zA-Z0-9/:. ]/g, '')
	}, 25, 50)
}

window.numberKey = function (e) {
	if (/^(8)$/g.test(e.keyCode)) return true;
	// return /^[0-9]$/g.test(String.fromCharCode(e.which));
	Gen.interval(() => {
		e.target.value = e.target.value.replace(/[^0-9]/g, '')
	}, 25, 50)
}

window.mobileKey = function (e) {
	if (/^(8)$/g.test(e.keyCode)) return true;
	// return /^[0-9+]$/g.test(String.fromCharCode(e.which));
	Gen.interval(() => {
		e.target.value = e.target.value.replace(/[^0-9+]/g, '')
	}, 25, 50)
}

window.phoneKey = function (e) {
	if (/^(8)$/g.test(e.keyCode)) return true;
	// return /^[0-9]$/g.test(String.fromCharCode(e.which));
	Gen.interval(() => {
		e.target.value = e.target.value.replace(/[^0-9]/g, '')
	}, 25, 50)
}

window.emailKey = function (e) {
	if (/^(8)$/g.test(e.keyCode)) return true;
	// return /^[a-zA-Z0-9@+._-]$/g.test(String.fromCharCode(e.which));
	Gen.interval(() => {
		e.target.value = e.target.value.replace(/[^a-zA-Z0-9@+._-]/g, '')
	}, 25, 50)
}

window.maxWord = function (e, limit) {
	if (e.key === " " && e.target.value.wordLength() == limit) return false
	if (e.key === " " && e.key === e.target.value.substr(-1)) return false
	return true
}

// default validation message
$.extend($.validator.messages, {
	required: function (p, el) {
		var element = $(el)
		var placement = $("[error='" + element.attr('name') + "']")
		var label = placement.attr('label') || element.attr('name')
		var msg = ['radio', 'checkbox'].indexOf(element.attr('type')) > -1 ? "Ceklist" : "Input"
		if (element.attr('name') === 'agree_term') {
			msg = "Harap Menyetujui"
		}
		return msg + " " + label + "."
	},
	email: "Input email yang benar.",
	number: "Input angka yang benar.",
	creditcard: "Please enter a valid credit card number.",
	equalTo: function (p, el) {
		var element = $(el)
		var placement = $("[error='" + element.attr('name') + "']")
		var label = placement.attr('label') || element.attr('name')
		return label + " tidak sesuai."
	},
	maxlength: $.validator.format("Input maksimal {0} karakter."),
	minlength: $.validator.format("Input minimal {0} karakter."),
	rangelength: $.validator.format("Input antara {0} s/d {1} karakter."),
	range: $.validator.format("Input antara {0} s/d {1}."),
	max: $.validator.format("Input maksimal {0}."),
	min: function (p, el) {
		var element = $(el)
		var placement = $("[error='" + element.attr('name') + "']")
		var label = placement.attr('label') || element.attr('name')
		return placement.data('msg-min') || "Input min " + p + "."
	}
});
$.validator.setDefaults({
	ignore: ".novalidate",
	// any other default options and/or rules
});
$.extend($.validator.methods, {
	email(value) {
		return /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
	}
})
$(document).on("click", ".view_password .btn_view", function () {
	var btn = $(this);
	var input = $(this).parents(".view_password").find("input")
	if (btn.hasClass("icon-eye-close")) {
		btn.removeClass("icon-eye-close").addClass("icon-eye")
		input.attr("type", "text")
	} else {
		btn.removeClass("icon-eye").addClass("icon-eye-close")
		input.attr("type", "password")
	}
})
